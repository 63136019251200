
import Vue from 'vue';

export default Vue.extend({
  name: 'singleOrderItem',
  props: ['name', 'id'],
  // components: { C360Icon },
  data: (): {
    hover: Boolean;
  } => ({
    hover: false,
  }),
  methods: {
    openAdvertiser(): void {
      const query = {
        id: this.id,
        tab: 'orderlist',
      };
      this.$router.replace({ name: 'home', query });
    },
  },
});
