
import Vue from 'vue';
import { Advertiser } from '../../../types/advertisers';
import { C360Icon } from '@c360/ui';
import singleOrderItem from './singleOrderItem.vue';
import debounce from 'lodash.debounce';

export default Vue.extend({
  name: 'AdvertisersForOrderList',
  props: [
    'advertiser',
    'advertiserName',
    'advertiserData',
    'partnerFilter',
    'currentSection',
    'campaignData',
    'superUser',
    'advertisersOnly',
  ],
  components: { C360Icon, singleOrderItem },
  data: (): {
    selectedItem: Number;
    selectedPartners: object | null;
    searchString: string;
    itemsLimits: Number;
    offset: Number;
    hover: Boolean;
  } => ({
    selectedItem: -1,
    selectedPartners: null,
    searchString: '',
    itemsLimits: 10,
    offset: 0,
    hover: false,
  }),
  computed: {
    isOnePage(): boolean {
      return this.advertiserData?.total <= this.itemsLimits;
    },
    isLastPage(): boolean {
      return this.offset + this.advertiserData?.advertisers?.length === parseInt(this.advertiserData?.total);
    },
    proposalRange(): string {
      const firstNumber = this.offset + 1;
      const secondNumber = this.offset + this.advertiserData?.advertisers?.length;
      return `${firstNumber} - ${secondNumber} of ${this.advertiserData?.total}`;
    },
    agencyName(): string {
      const user = this.$store.getters.user;
      return this.superUser ? this.selectedPartners.value : user.Agency;
    },
    loading(): boolean {
      return this.$store.state.linearAdmin?.loading;
    },
    advertiserTotal(): string | null {
      return this.advertiserData?.total ? `(${this.advertiserData?.total})` : null;
    },
    hasCampaignData(): boolean {
      return this.campaignData.length;
    },
    formatedExportData() {
      if (!this.hasCampaignData) return null;
      return {
        advertiserId: this.advertiser,
        advertiserName: this.advertiserName,
        campaigns: '',
        daterange: 'alltime',
        tab: 'summary',
      };
    },
    filterMessage(): string {
      return this.$store.state.linearAdmin?.loading ? 'Loading filter' : 'N/A';
    },
  },
  methods: {
    isSelected(id: string): boolean {
      return this.advertiser === id;
    },
    updateSearch(search, offset): void {
      this.$emit('paginateOrders', { advertiser: search, offset });
    },
    openAdvertiser(advertiser: Advertiser): void {
      const query = {
        id: advertiser.id,
        tab: 'orderlist',
      };
      // open new window to avoid losing context
      this.$router.replace({ name: 'home', query });
    },

    prevPage(): void {
      this.offset = this.offset - 10;
      this.updateSearch(this.searchString, this.offset);
    },
    nextPage(): void {
      this.offset = this.offset + 10;
      this.updateSearch(this.searchString, this.offset);
    },
    debouncedSearch: debounce(function (): void {
      this.offset = 0;
      this.updateSearch(this.searchString, this.offset);
    }, 1000),
  },
  watch: {
    searchString: {
      handler(): void {
        this.debouncedSearch();
      },
    },
    advertiserData: {
      handler(): void {
        this.selectedItem = -1;
      },
    },
  },
});
